<template>
  <div>
    <v-page class="py-16 text-center" justify="center" align="center" no-auto-height>
      <v-row>
        <v-col class="font-weight-bold" style="font-size: 1rem;">{{ $t('arrangementen.header') }}</v-col>
      </v-row>
      <v-row>
        <v-col>
          <h1 class="dz c-primary d-none d-sm-block" style="font-size: 4rem;">{{ $t('arrangementen.intro-header') }}</h1>
          <h1 class="dz c-primary d-block d-sm-none" style="font-size: 3rem;">{{ $t('arrangementen.intro-header') }}</h1>
        </v-col>
      </v-row>
      <v-row align="center" justify="center">
        <v-col cols="12" md="12" lg="8" xl="6">
          {{ $t('arrangementen.intro') }}
        </v-col>
      </v-row>

    </v-page>
    <!-- <v-page background-color="#FFF" :background="require('@/assets/watermerk.svg?fill=#F7F7F7')" style="background-size: 100%;background-position:center;" class="py-16" no-auto-height>
      <v-container :fluid="$vuetify.breakpoint.mdOnly">
        <v-row justify="center" align="center">
          <v-col cols="12" md="6" lg="7">
            <v-img :src="require('@/assets/nazomer.png')" :aspect-ratio="16/9"></v-img>
          </v-col>
          <v-col>
            <v-row>
              <v-col><h1 class="dz" style="font-size: 3rem;">{{ $t('arrangementen.nazomer-header') }}</h1></v-col>
            </v-row>
            <v-row>
              <v-col class="text-left">
                {{ $t('arrangementen.nazomer') }}
                <ul >
                  <li v-for="item in $t('arrangementen.nazomer-items')" :key="item">{{ item }}</li>
                </ul>
              </v-col>

            </v-row>
            <v-row>
              <v-col><v-btn x-large class="mt-6 rounded-0" style="color: black; font-weight: bold" href="https://app.mews.com/distributor/7e66bd87-43d9-4d26-9301-ad9800bf0b87" target="_blank">{{ $t('reserveren') }}</v-btn></v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-page>
    <v-page background-color="#282828" style="background-size: 100%;background-position:center;" class="py-16" no-auto-height dark>
      <v-container :fluid="$vuetify.breakpoint.mdOnly">
        <v-row justify="center" align="center">
          <v-col>
            <v-row>
              <v-col><h1 class="dz" style="font-size: 3rem;">{{ $t('arrangementen.trouwen-header') }}</h1></v-col>
            </v-row>
            <v-row>
              <v-col class="text-left">
                {{ $t('arrangementen.trouwen') }}
              </v-col>
            </v-row>

          </v-col>
          <v-col cols="12" md="6" lg="7">
            <v-img :src="require('@/assets/trouwen.jpg')" :aspect-ratio="16/9"></v-img>
          </v-col>
        </v-row>
      </v-container>
    </v-page>
    <v-page background-color="#91B6BA" :background="require('@/assets/watermerk.svg?fill=#FFFFFF')" style="background-size: 100%;background-position:center;" class="py-16" no-auto-height>
      <v-container :fluid="$vuetify.breakpoint.mdOnly">
        <v-row justify="center" align="center">
          <v-col cols="12" md="6" lg="7">
            <v-img :src="require('@/assets/dining.jpg')"></v-img>
          </v-col>
          <v-col>
            <v-row>
              <v-col><h1 class="dz" style="font-size: 3rem;">{{ $t('arrangementen.dining-header') }}</h1></v-col>
            </v-row>
            <v-row no-gutters>
              <v-col class="text-left font-weight-bold">{{ $t('arrangementen.prijs', {prijs: "185,00"}) }}</v-col>
            </v-row>
            <v-row>
              <v-col class="text-left">{{ $t('arrangementen.dining') }}
              <ul>
                <li>{{ $t('arrangementen.dining-list1') }}</li>
                <li>{{ $t('arrangementen.dining-list2') }}</li>
                <li>{{ $t('arrangementen.dining-list3') }}</li>
              </ul></v-col>
            </v-row>
            <v-row>
              <v-col><v-btn x-large class="mt-6 rounded-0" style="color: black; font-weight: bold" href="https://app.mews.com/distributor/2ae4ab34-b1c2-4010-a4b7-ad3f01546502" target="_blank">{{ $t('reserveren') }}</v-btn></v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-page>
    <v-page background-color="#282828" style="background-size: 100%;background-position:center;" class="py-16" no-auto-height dark>
      <v-container :fluid="$vuetify.breakpoint.mdOnly">
        <v-row justify="center" align="center">
          <v-col>
            <v-row>
              <v-col><h1 class="dz c-primary" style="font-size: 3rem;">{{ $t('arrangementen.fiets-header') }}</h1></v-col>
            </v-row>
            <v-row no-gutters>
              <v-col class="text-left font-weight-bold">{{ $t('arrangementen.prijs', {prijs: "84,50"}) }}</v-col>
            </v-row>
            <v-row>
              <v-col class="text-left">{{ $t('arrangementen.fiets') }}
              <ul>
                <li>{{ $t('arrangementen.fiets-list1') }}</li>
                <li>{{ $t('arrangementen.fiets-list2') }}</li>
              </ul></v-col>
            </v-row>
            <v-row>
              <v-col><v-btn color="primary" x-large class="mt-6 rounded-0" style="color: black; font-weight: bold" href="https://app.mews.com/distributor/8352d3d4-c9b8-4444-bfc7-ad3f0154b63a" target="_blank">{{ $t('reserveren') }}</v-btn></v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="6" lg="7">
            <v-img :src="require('@/assets/wandel arrangement.jpg')"></v-img>
          </v-col>
        </v-row>
      </v-container>
    </v-page>
    <v-page background-color="#FFF" :background="require('@/assets/watermerk.svg?fill=#F7F7F7')" style="background-size: 100%;background-position:center;" class="py-16" no-auto-height>
      <v-container :fluid="$vuetify.breakpoint.mdOnly">
        <v-row justify="center" align="center">
          <v-col cols="12" md="6" lg="7">
            <v-img :src="require('@/assets/sloep.jpeg')"></v-img>
          </v-col>
          <v-col>
            <v-row>
              <v-col><h1 class="dz c-primary" style="font-size: 3rem;">{{ $t('arrangementen.sloep-header') }}</h1></v-col>
            </v-row>
            <v-row no-gutters>
              <v-col class="text-left font-weight-bold">{{ $t('arrangementen.prijs', {prijs: "182,00"}) }}</v-col>
            </v-row>
            <v-row>
              <v-col class="text-left">{{ $t('arrangementen.sloep') }}
              <ul>
                <li>{{ $t('arrangementen.sloep-list1') }}</li>
                <li>{{ $t('arrangementen.sloep-list2') }}</li>
                <li>{{ $t('arrangementen.sloep-list3') }}</li>
                <li>{{ $t('arrangementen.sloep-list4') }}</li>
              </ul>
              </v-col>
            </v-row>
            <v-row>
              <v-col><v-btn color="primary" x-large class="mt-6 rounded-0" style="color: black; font-weight: bold" href="https://app.mews.com/distributor/8dec83a9-4107-47a8-aa7f-ad1d00cecede" target="_blank">{{ $t('reserveren') }}</v-btn></v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-page>-->
    
    <template v-for="(arrangement, arrangementIdx) in arrangementen">
      <variant1 :key="arrangementIdx" :arrangement="arrangement" :right-text="arrangementIdx % 2 === 0" v-if="arrangementIdx % 3 === 0"></variant1>
      <variant2 :key="arrangementIdx" :arrangement="arrangement" :right-text="arrangementIdx % 2 === 0" v-if="arrangementIdx % 3 === 1"></variant2>
      <variant3 :key="arrangementIdx" :arrangement="arrangement" :right-text="arrangementIdx % 2 === 0" v-if="arrangementIdx % 3 === 2"></variant3>
    </template>

    <Footer></Footer>
  </div>
</template>

<script>
import VPage from "@/components/VPage";
import Footer from "@/views/Footer";
import http from "../http";
import Variant1 from "../components/arrangementen/Variant1";
import Variant2 from "../components/arrangementen/Variant2";
import Variant3 from "../components/arrangementen/Variant3";

export default {
  name: "Arrangementen",
  components: {Variant3, Variant2, Variant1, VPage, Footer},
  data() {
    return {
      arrangementen: []
    }
  },
  async mounted() {

    this.arrangementen = (await http.get(`/p/${this.$i18n.locale}/arrangement?include_body=1`)).data.data;
  }
}
</script>

<style scoped>

</style>
