<template>
  <v-page background-color="#282828" style="background-size: 100%;background-position:center;" class="py-16" no-auto-height dark>
    <v-container :fluid="$vuetify.breakpoint.mdOnly">
      <v-row justify="center" align="center">
        <v-col :order-md="rightText ? 1 : 0">
          <v-row>
            <v-col><h1 class="dz" style="font-size: 4rem;">{{ arrangement.title }}</h1></v-col>
          </v-row>
          <v-row no-gutters>
            <v-col class="text-left" v-html="arrangement.content">
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="6" lg="7" order="0">
          <v-img :src="arrangement.featured_image"></v-img>
        </v-col>
      </v-row>
    </v-container>
  </v-page>
</template>

<script>
import VPage from "@/components/VPage";
export default {
  name: "Variant2",
  components: {
    VPage
  },
  props: {
    rightText: {
      type: Boolean,
      default: undefined
    },
    arrangement: {
      type: Object,
      default: () => null
    }
  },
}
</script>

<style scoped>

</style>
